import { createReactLifecycles } from 'single-spa-workfront';
import { ModernWorkSchedulingGeneralContainer } from './index';

export { ModernWorkSchedulingGeneral } from './index';

const lifecycles = createReactLifecycles({
    rootComponent: ModernWorkSchedulingGeneralContainer,
    rootNode: {
        className: 'flex flex-col flex-1 h-full w-full overflow-auto',
    },
});

export const { bootstrap } = lifecycles;
export const { mount } = lifecycles;
export const { unmount } = lifecycles;

export default ModernWorkSchedulingGeneralContainer;
