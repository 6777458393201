import { Localization } from '@workfront/localize-react';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { css } from 'react-emotion';
import { Spinner } from '@phoenix/all';
import { Sections } from '../../constants/schedulingTableConstants';
import { IWorkSchedulingCombinedState, IWorkSchedulingDispatchProp } from '../../data-flow/types';
import {
    apiRequestMessageKeys,
    filtersMessageKeysForContent,
    serviceNamesPerKey,
} from '../../messageKeys';
import { FilterContent } from './FilterContent';
import { RightWidgetHOC } from '../hoc/RightWidget/RightWidgetHOC';
import { closeAllFilterWidgets } from '../../data-flow/data/assignedDataActions/commonActionGroups/contouringActionGroup';
import { getSchedulingAreaData } from '../../data-flow/areaData/selectors/getSchedulingAreaData/getSchedulingAreaData';
import { IProjectStatusesForFilter } from '../../data-flow/filters/IFiltersState';
import { IAreaState } from '../../data-flow/areaData/areaRelatedInitialDataState';
import { projectStatusesSelector } from '../../data-flow/filters/selectors/filterIDSelector';
import { IFilterFields } from '../Scheduling/SchedulingPanels';

const filterContentTestIds = {
    spinner: 'filter-spinner',
};

interface IFilterContainerProps extends IWorkSchedulingDispatchProp {
    isFilterOpen: boolean;
    filterFields: IFilterFields;
}

interface IFilterContainerStateProps {
    sectionType: Sections;
    projectStatuses: IProjectStatusesForFilter[];
    schedulingAreaData: IAreaState;
}

const FilterContainerComponent: React.FunctionComponent<
    IFilterContainerProps & IFilterContainerStateProps
> = (props) => {
    const {
        isFilterOpen,
        projectStatuses,
        schedulingAreaData,
        sectionType,
        filterFields,
        dispatch,
    } = props;
    const { quickFilterFields, advancedFilterFields } = filterFields[sectionType];

    const filterClose = useCallback(() => {
        dispatch(closeAllFilterWidgets());
    }, [dispatch]);

    if (!isFilterOpen) {
        return null;
    }

    return (
        <RightWidgetHOC testID="filter-container">
            <Localization<string[]>
                messageKeys={[
                    ...filtersMessageKeysForContent,
                    ...apiRequestMessageKeys,
                    serviceNamesPerKey.filter,
                ]}
            >
                {(messages) => {
                    const [loadingLabel] = messages;
                    if (quickFilterFields.length) {
                        return (
                            <FilterContent
                                labels={messages}
                                onClose={filterClose}
                                sectionType={sectionType}
                                projectStatuses={projectStatuses}
                                schedulingAreaData={schedulingAreaData}
                                quickFilterFields={quickFilterFields}
                                advancedFilterFields={advancedFilterFields}
                            />
                        );
                    }
                    return (
                        <div className={spinnerWrapper}>
                            <Spinner testId={filterContentTestIds.spinner}>
                                {loadingLabel('Loading...')}
                            </Spinner>
                        </div>
                    );
                }}
            </Localization>
        </RightWidgetHOC>
    );
};

const spinnerWrapper = css`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const mapStateToProps = (state: IWorkSchedulingCombinedState): IFilterContainerStateProps => ({
    sectionType: state.Filters[Sections.PEOPLE_WORKLOAD].isFilterOpen
        ? Sections.PEOPLE_WORKLOAD
        : Sections.UNASSIGNED_WORK,
    projectStatuses: projectStatusesSelector(state),
    schedulingAreaData: getSchedulingAreaData(state),
});

export const FilterContainer = connect(mapStateToProps)(FilterContainerComponent);
