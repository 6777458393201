import { Font, Tooltip, primary } from '@phoenix/all';
import { Localization } from '@workfront/localize-react';
import { css } from 'emotion';
import * as React from 'react';
import { Project } from 'workfront-objcodes';
import { IProjectColor } from '../../../../data-flow/data/IDataState';
import { getFontType, IFontType } from '../../../../util/getFontType';
import { localizationClient } from '../../../../constants/LocalizationClientFactory';
import { PROJECT_TASK_TOOLTIP_DELAY } from '../../../../constants/dataConstatnts';

interface IProjectName {
    name: string;
    showTooltip?: boolean;
    isAccessible?: boolean;
    ID?: string;
    projectColoredData?: IProjectColor;
    projectStatusLabel?: string;
    shouldActAsLink?: boolean;
    workRequiredHours?: number;
    expanded?: boolean;
}

const messageKeys = ['project', 'workloadbalancer.total.work'];
const projectNameStyles = { display: 'flex' };

export const ProjectName: React.FC<IProjectName> = React.memo((props) => {
    const tooltipContent = (
        <div className={tooltipContentClass}>
            {localizationClient.getTextSync(
                'resourcescheduling.inaccessible.project.description',
                "You don't have access to this project"
            )}
        </div>
    );

    const {
        name,
        isAccessible,
        showTooltip,
        ID,
        projectColoredData,
        projectStatusLabel,
        shouldActAsLink = true,
        workRequiredHours,
        expanded,
    } = props;
    let color;
    let lineClassName;

    if (isAccessible) {
        color = primary.gray(600);
        lineClassName = projectBlueLine(
            projectColoredData ? projectColoredData.RGB : primary.blue(500),
            expanded
        );
    } else {
        color = primary.gray(400);
        lineClassName = projectGrayLine(expanded);
    }

    const fontComponentDetails = getFontType(isAccessible, ID, Project);

    let title: any = name;

    if (projectColoredData && projectStatusLabel) {
        title = (
            <>
                <div>{name}</div>
                <div className={flexTopAlignWrapper}>
                    <span className={circleBadgeStyle(projectColoredData.RGB)} />
                    <span>{projectStatusLabel}</span>
                </div>
            </>
        );
    }

    const nameAsLinkProps = getLinkRelatedProps(shouldActAsLink, fontComponentDetails);

    const projectName = (
        <Tooltip content={title} delay={PROJECT_TASK_TOOLTIP_DELAY} style={projectNameStyles}>
            <span className={projectNameStyle(color)}>
                <Localization<string[]> messageKeys={messageKeys}>
                    {({ _t }) => {
                        return (
                            <Font
                                style={{ color }}
                                to={nameAsLinkProps.to}
                                type={nameAsLinkProps.type}
                                target={nameAsLinkProps.target}
                                aria-label={`${_t.project('Project')}: ${name}, ${
                                    workRequiredHours
                                        ? _t['workloadbalancer.total.work'](
                                              'Total work required: {0} hours',
                                              workRequiredHours
                                          )
                                        : ''
                                }`}
                                className={fontStyle}
                            >
                                {name}
                            </Font>
                        );
                    }}
                </Localization>
            </span>
        </Tooltip>
    );

    return (
        <>
            <div className={lineClassName} />
            <div className={nameBlock}>
                {showTooltip ? (
                    <Tooltip
                        content={tooltipContent}
                        delay={PROJECT_TASK_TOOLTIP_DELAY}
                        style={projectNameStyles}
                    >
                        {projectName}
                    </Tooltip>
                ) : (
                    projectName
                )}
            </div>
        </>
    );
});

export interface ILinkRelatedProps extends IFontType {
    target: string;
}

/**
 *
 * @param shouldActAsLink
 * @param fontComponentDetails
 */
export const getLinkRelatedProps = (
    shouldActAsLink: boolean,
    fontComponentDetails?: IFontType
): ILinkRelatedProps => {
    return shouldActAsLink && fontComponentDetails
        ? {
              to: fontComponentDetails.to,
              target: '_blank',
              type: fontComponentDetails.type,
          }
        : ({} as ILinkRelatedProps);
};

const fontStyle = css`
    font-weight: 600;
    & > span {
        display: flex;
    }
`;

const projectNameFontSize = 14;

const flexTopAlignWrapper = css`
    display: flex;
    align-items: center;
`;

const circleBadgeStyle = (color: string): string => css`
    height: 6px;
    width: 6px;
    background-color: ${color};
    border-radius: 50%;
    border: 1px solid ${primary.white()};
    margin-right: 6px;
`;

const projectNameStyle = (color: string): string => css`
    color: ${color};
    padding-right: 8px;
    width: 100%;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${projectNameFontSize}px;
    line-height: ${projectNameFontSize}px;
`;

const tooltipContentClass = css`
    padding: 4px;
`;

const nameBlock = css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

const line = (expanded): string => css`
    position: absolute;
    height: calc(100% - ${expanded ? '3px' : '8px'});
    width: 4px;
    border-radius: ${expanded ? '0 2px 0 0' : '0 2px 2px 0'};
    left: 0px;
    top: 4px;
`;

const projectBlueLine = (bgColor, expanded): string => css`
    ${line(expanded)};
    background-color: ${bgColor};
`;

const projectGrayLine = (expanded): string => css`
    ${line(expanded)};
    background-color: ${primary.gray(200)};
`;
