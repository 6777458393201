import { Localization } from '@workfront/localize-react';
import { css } from 'emotion';
import * as React from 'react';
import {
    convertMinuteToHour,
    formatNumber,
    getPercentToShow,
    getTotalPercentForTooltip,
} from '../../../../util/utilities';
import { getHourAllocationDetail, getNonWorkHoursDuration } from '../../../TooltipOptions';

interface IAllocationTooltipContentProps extends React.HTMLAttributes<HTMLDivElement> {
    workPerDayHour?: number;
    availableHourForUser?: number;
    isUserTimeOff?: boolean;
    scheduleHour?: number;
    nonWorkHours?: string[];
    timeOffMessageKey: string;
    timeOffMessageFallback: string;
    isForFullTimeOff?: boolean;
    isNotWorkingDay?: boolean;
    showAllocationsInHoursMode?: boolean;
    halfTimeOffCondition?: boolean;
    isWeekMode?: boolean;
    averageOfSchedule?: number;
}

export const AllocationTooltipContent: React.FunctionComponent<IAllocationTooltipContentProps> =
    React.memo((props) => {
        const {
            workPerDayHour,
            availableHourForUser,
            nonWorkHours,
            isUserTimeOff,
            isForFullTimeOff,
            timeOffMessageKey,
            timeOffMessageFallback,
            showAllocationsInHoursMode,
            scheduleHour,
            halfTimeOffCondition,
            isNotWorkingDay,
            isWeekMode,
            averageOfSchedule,
        } = props;

        const isNotWorkingDayOrTimeOff = isNotWorkingDay || timeOffMessageKey;
        let allocationPercent;
        let totalHourPercent;
        if (!showAllocationsInHoursMode) {
            let scheduleHourForCalculation;
            if (scheduleHour) {
                scheduleHourForCalculation =
                    isWeekMode && averageOfSchedule
                        ? convertMinuteToHour(averageOfSchedule)
                        : convertMinuteToHour(scheduleHour);
            }
            allocationPercent = getPercentToShow(
                availableHourForUser,
                workPerDayHour,
                scheduleHourForCalculation
            );
            totalHourPercent = getTotalPercentForTooltip(
                halfTimeOffCondition,
                isNotWorkingDayOrTimeOff,
                scheduleHourForCalculation,
                availableHourForUser
            );
        }

        const overAllocatedPercent =
            parseInt(allocationPercent, 10) > parseInt(totalHourPercent, 10);
        const overAllocatedPercentStyleName = overAllocatedPercent ? overAllocatedPercentStyle : '';

        const timeOffMessage = timeOffMessageKey ? (
            <div>
                <Localization<string>
                    messageKey={timeOffMessageKey}
                    fallback={timeOffMessageFallback}
                />
            </div>
        ) : null;

        if (isForFullTimeOff) {
            return <div>{timeOffMessage}</div>;
        }

        return (
            <div>
                {showAllocationsInHoursMode ? (
                    <>
                        {getHourAllocationDetail(workPerDayHour, availableHourForUser)} <br />
                        <Localization<string>
                            messageKey="enum.baselinepropertyenum.workrequired"
                            fallback="Planned hours"
                        />
                        : {formatNumber(workPerDayHour || 0)}
                        <Localization<string> messageKey="hour.abbr.short" fallback="h" /> <br />
                        <Localization<string> messageKey="capacity" fallback="Capacity" />:{' '}
                        {formatNumber(availableHourForUser)}
                        <Localization<string> messageKey="hour.abbr.short" fallback="h" />
                        {nonWorkHours && nonWorkHours.length > 0 && (
                            <div>{getNonWorkHoursDuration(nonWorkHours, isUserTimeOff)}</div>
                        )}
                    </>
                ) : (
                    <span>
                        <span className={semiBoldStyle}>
                            <Localization<string> messageKey="allocation" fallback="Allocation" />:{' '}
                        </span>
                        <span className={overAllocatedPercentStyleName}>{allocationPercent} </span>
                        <span className={semiBoldStyle}>/ {totalHourPercent}</span>
                    </span>
                )}
                {timeOffMessage}
            </div>
        );
    });

const semiBoldStyle = css`
    font-weight: 500;
`;

const overAllocatedPercentStyle = css`
    color: #e46667;
`;
