import React, { Suspense, lazy, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, FieldGroup } from '@workfront/panel-components';
import { Sections } from '../../constants/schedulingTableConstants';
import { BulkAssignmentWrapperForLazy } from '../BulkAssignments/BulkAssignmentWrapperForLazy';
import { FilterContainer } from '../filters/FilterContainer';
import { RoleSummaryContainer } from '../roleSummary/RoleSummaryContainer';
import { getFilterFields } from '../../services/api-services/filters/filterApiService';
import {
    TIsBulkAssignmentPanelOpened,
    TIsPanelOpened,
    TShowRoleSummary,
} from '../../data-flow/data/IDataState';
import {
    getAdvancedFilterFields,
    mutateQuickFilterFields,
} from '../../constants/filters/filterOptions';
import { IAreaState } from '../../data-flow/areaData/areaRelatedInitialDataState';
import { projectStatusesSelector } from '../../data-flow/filters/selectors/filterIDSelector';
import {
    IBaseFilterFields,
    IProjectStatusesForFilter,
} from '../../data-flow/filters/IFiltersState';

const SettingsPanel = lazy(() =>
    import('../Settings/SettingsPanel').then((module) => ({ default: module.SettingsPanel }))
);

interface ISchedulingPanelProps {
    isFilterOpen: boolean;
    showRoleSummary: TShowRoleSummary;
    isPanelOpened: TIsPanelOpened;
    isBulkAssignmentPanelOpened: TIsBulkAssignmentPanelOpened;
    schedulingAreaData: IAreaState;
}

interface ISchedulingPanelPropsFromState {
    projectStatuses: IProjectStatusesForFilter[];
}

export interface IFilterFields {
    [Sections.UNASSIGNED_WORK]: IBaseFilterFields;
    [Sections.PEOPLE_WORKLOAD]: IBaseFilterFields;
}

const SchedulingPanelsComponent: React.FunctionComponent<
    ISchedulingPanelProps & ISchedulingPanelPropsFromState
> = (props) => {
    const {
        isFilterOpen,
        isPanelOpened,
        isBulkAssignmentPanelOpened,
        showRoleSummary,
        schedulingAreaData,
        projectStatuses,
    } = props;

    const [filterFields, setFilterFields] = useState<IFilterFields>({
        [Sections.UNASSIGNED_WORK]: {
            quickFilterFields: [],
            advancedFilterFields: [],
        },
        [Sections.PEOPLE_WORKLOAD]: {
            quickFilterFields: [],
            advancedFilterFields: [],
        },
    });

    const [projectSortableFields, setProjectSortableFields] = useState<IBaseFilterFields>({
        quickFilterFields: [] as Field[],
        advancedFilterFields: [] as FieldGroup[],
    });

    useEffect(() => {
        getFilterFields().then((fields) => {
            const {
                quickFilterFields,
                unassignedQuickFilterFields,
                advancedFilterFields,
                projectsSortQuickFilterFields,
                projectsSortAdvancedFilterFields,
            } = fields;
            const { schedulingAreaObjCode } = schedulingAreaData;

            setFilterFields({
                [Sections.PEOPLE_WORKLOAD]: {
                    quickFilterFields: mutateQuickFilterFields(
                        Sections.PEOPLE_WORKLOAD,
                        schedulingAreaObjCode,
                        quickFilterFields,
                        projectStatuses
                    ),
                    advancedFilterFields: getAdvancedFilterFields(
                        Sections.PEOPLE_WORKLOAD,
                        advancedFilterFields
                    ),
                },
                [Sections.UNASSIGNED_WORK]: {
                    quickFilterFields: mutateQuickFilterFields(
                        Sections.UNASSIGNED_WORK,
                        schedulingAreaObjCode,
                        unassignedQuickFilterFields,
                        projectStatuses
                    ),
                    advancedFilterFields: getAdvancedFilterFields(
                        Sections.UNASSIGNED_WORK,
                        advancedFilterFields
                    ),
                },
            });
            setProjectSortableFields({
                quickFilterFields: projectsSortQuickFilterFields,
                advancedFilterFields: projectsSortAdvancedFilterFields,
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FilterContainer isFilterOpen={isFilterOpen} filterFields={filterFields} />
            {showRoleSummary ? <RoleSummaryContainer /> : null}

            <Suspense fallback={null}>
                <SettingsPanel
                    isPanelOpened={isPanelOpened}
                    projectSortableFields={projectSortableFields}
                />
            </Suspense>

            {isBulkAssignmentPanelOpened && (
                <BulkAssignmentWrapperForLazy schedulingAreaData={schedulingAreaData} />
            )}
        </>
    );
};

const mapStateToProps = (state): ISchedulingPanelPropsFromState => ({
    projectStatuses: projectStatusesSelector(state),
});

export const SchedulingPanels = connect(mapStateToProps)(SchedulingPanelsComponent);
